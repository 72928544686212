<!-- 知识大讲堂 -->
<template>
  <div style="height:100%">
    <!-- tab栏 -->
    <van-tabs v-model="listfrom.gntId" color="#188EFB" sticky @change="tabClick(listfrom.gntId)">
      <van-tab v-for="item in tabList" :key="item.GNTId" :title="item.Name" :name="item.GNTId"></van-tab>
    </van-tabs>
    <div class="information">
      <!-- 全部 -->
      <div class="center" v-for="item in list" :key="item.index">
        <router-link :to="'/huayang/classroom/particulars/' + item.GNId">
          <div class="Content">
            <div style="display: inline-block" class="ContentA">
              <img :src="item.ThemeImg" alt="" />
            </div>
            <div style="display: inline-block;margin-left:10px" class="ContentB">
              <span class="van-multi-ellipsis--l3" style="color: #000">{{
                item.Title
              }}</span>
              <br />
              <span class="centertime">{{ item.Summary }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >资源看板</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetGoodNewsPage,WeGetGNTypeList } from "@/api/Hyapi";
export default {
  data() {
    return {
      active: 0,
      tabactive: 0,
      akId: 0, //tab栏
      //资讯列表
      listfrom: {
        page: 1,
        limit: 999,
        gntId: 1,
      }, //查询传参
      hidden: true, //没有更多数据了
      list:{},
      tabList:[],
    };
  },
  methods: {
    // 切换资讯分类
    tabClick(e){
      this.getList();
    },
    // 获取资讯分类
    getGNTypeList(){
      WeGetGNTypeList().then((res)=>{
        this.tabList = res.data.data
      })
    },
    // 获取分页列表
    getList() {
      WeGetGoodNewsPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getGNTypeList();
    this.getList();
  },
};
</script>
<style >
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
